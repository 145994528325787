/* Set the colors for each value */
.custom-rating.red {
    color: red;
  }
  
  .custom-rating.orange {
    color: orange;
  }
  
  .custom-rating.yellow {
    color: yellow;
  }
  
  .custom-rating.lime {
    color: lime;
  }
  
  .custom-rating.green {
    color: green;
  }
  
  /* Change color on hover for each value */
  .custom-rating.red:hover,
  .custom-rating.red.ant-rate-star-full {
    color: red;
  }
  
  .custom-rating.orange:hover,
  .custom-rating.orange.ant-rate-star-full {
    color: orange;
  }
  
  .custom-rating.yellow:hover,
  .custom-rating.yellow.ant-rate-star-full {
    color: yellow;
  }
  
  .custom-rating.lemon:hover,
  .custom-rating.lemon.ant-rate-star-full {
    color: lime;
  }
  
  .custom-rating.green:hover,
  .custom-rating.green.ant-rate-star-full {
    color: green;
  }
  .small-rating {
    font-size: 16px; /* Adjust the size as needed */
  }

  .smaller-rating {
    font-size: 8px;
  }
  
  .large-rating {
    font-size: 32px; /* Adjust the size as needed */
  }

  
   /* Styles for screens between 768px and 899px */
   @media screen and (max-width: 899px) {
    .Listing {
      flex-direction: column; /* Stack listings vertically */
    }

    .Image {
      width: 100% !important;  /* Make images full-width */
    }

    .Image img {
      width: 100%; /* Make images full-width */
      height: auto;
    }

    /* Adjust other styles as needed for smaller screens */
  }

  /* Styles for screens smaller than 768px (e.g., mobile devices) */
  @media screen and (max-width: 767px) {
    /* Center the listing content on smaller screens */
    .Listing {
      flex-direction: column; /* Stack listings vertically */
      align-items: center; /* Center content horizontally */
    }
    .form-img {
      display: none;
    }

    /* Adjust other styles for even smaller screens */
  }

  /* Your CSS styles for screens between 600px and 624px wide go here */
  @media screen and (max-width: 599px) {
    .listingCard .Content {
      width: 100% !important;
    }
  }

  /********** Template CSS **********/
:root {
  --primary: #355EFC;
  --secondary: #E93C05;
  --tertiary: #555555;
  --light: #DFE4FD;
  --dark: #011A41;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}

h1,
.h1,
h2,
.h2,
.fw-bold {
  font-weight: 700 !important;
}

h3,
.h3,
h4,
.h4,
.fw-medium {
  font-weight: 600 !important;
}

h5,
.h5,
h6,
.h6,
.fw-semi-bold {
  font-weight: 500 !important;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  transition: .5s;
  font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
  color: var(--light);
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

.feature .feature-box,
.feature .feature-box * {
  transition: .5s !important;
}

.feature .feature-box:hover {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.feature .feature-box:hover * {
  color: #FFFFFF !important;
}


.form{

  position: relative;
  width: 400px;
}

.form .fa-search{

  position: absolute;
  top:20px;
  left: 20px;
  color: #9ca3af;

}

.form span{

      position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;

}

.form-input{

  height: 55px;
  text-indent: 33px;
  border-radius: 10px;
}

.form-input:focus{
  border:none;
}

.login-btn{
  padding: 13px 20px 12px;
  background-color: #000;
  border-radius: 4px;
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  margin-bottom: 24px;
  width: 100%;
  border-color: #000;
}

.login-btn:hover {
  border: '1px solid #000';
  border-color: #000;
  background-color: 'transparent';
  color: '#000';
}

.modal-confirm {		
  color: #636363;
  width: 325px;
  margin: 30px auto;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}
.modal-confirm .modal-header {
  border-bottom: none;   
      position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -15px;
}
.modal-confirm .form-control, .modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px; 
}
.modal-confirm .close {
      position: absolute;
  top: -5px;
  right: -5px;
}	
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}	
.modal-confirm .icon-box {
  color: #fff;		
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 9;
  background: #82ce34;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.modal-confirm .icon-box i {
  font-size: 58px;
  position: relative;
  top: 3px;
}
.modal-confirm.modal-dialog {
  margin-top: 80px;
}
.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #82ce34;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
}
.modal-confirm .btn:hover, .modal-confirm .btn:focus {
  background: #6fb32b;
  outline: none;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

@media (min-width: 769px) {
  /* Sticky Card CSS */
.sticky-list-card {
  position: sticky;
  top: 135px; /* Fixed 20 pixels from the top of the viewport */
  /* right: 20px; Adjust the value as needed */
  /* Other sticky card styles */
}

}

.st0{fill:#fff}
.st1{fill:#b5dfea}
.st2{opacity:.55;fill:#90cedd}
.st3{fill:#d7f0f9}.st4{fill:#0582c1}
.st5{fill:#79c9e8}.st6{fill:#ffbf4d}
.st7{fill:#00668e}.st8{fill:#05556d}
.st9{fill:#f98d3d}.st10{fill:#ed701b}
.st11{fill:none}.st12{fill:#efaa3a}
.st13{opacity:.29;fill:#f98d2b}
.st14{fill:#49b4d6}.st15{fill:#ff9f50}
.st16{fill:#f77e2d}
.st17{opacity:.55;fill:url(#SVGID_1_)}